<template>
  <div>
    <div class="fx-row bac-fff fx-1">
      <!-- 资源分组 -->
      <ResourceGrouping
        title="课程分组"
        pageType="student"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @clickGroupNode="_clickGroupNode"
      />

      <!-- 列表 -->
      <div class="w200 fx-1">
          <div class="empty-box" v-if="!tableData.length">
            <PlaceholderMap text="暂无课程噢~" />
          </div>
          <div v-else class="set-box">
            <div class="container-warp ">
            <div
              class="course-item"
              v-for="(item, index) in tableData"
              :key="index"
              @click="courseDetail(item)"
            >
              <div class="img-box">
                <img v-if="!item.cover" src="@/assets/img/Course.png" alt="" />
                <img v-else :src="item.cover" alt="" />
                <!-- <div class="pitch-box">共{{item.}}节</div> -->
                <!-- <div class="state-box">已下架</div> -->
              </div>
              <div class="bottom-box">
                <div class="name-box">
                  <img v-if="item.areLearning==1" src="@/assets/img/Learning.png" alt="">
                  <div>{{ item.courseName }}</div>
                  <!-- <div>入职培训</div> -->
                </div>
                <!-- <div class="progress-box">
                <div style="margin-right: 10px">已学10%</div>
                <el-progress :percentage="50" :show-text="false"></el-progress>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          class="fx-row-end pt16 pagination"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchForm.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceGrouping from '@/components/resourceGrouping/index'
import { getCourseGroupApi, getCourseListApi } from '@/api/course'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
export default {
  components: {
    ResourceGrouping,
    PlaceholderMap
  },
  data () {
    return {
      tableData: [],
      treeData: [],
      defaultProps: {
        children: 'id',
        label: 'groupName'
      },
      searchForm: {
        page: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  created () {
    this.getCourseGroup()
    this.getCourseList()
  },
  mounted () {
  },
  methods: {
    getCourseGroup () {
      // 获取分组
      getCourseGroupApi({}).then((res) => {
        if (res.code === 200) {
          this.treeData = res.data
        }
      })
    },
    getCourseList (item) {
      getCourseListApi({
        groupId: item && item.id
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    _clickGroupNode (data) {
      // 点击分组查询
      this.getCourseList(data)
    },
    courseDetail (item) {
      this.$router.push({
        path: '/courseDetail?id=' + item.id
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.getCourseList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.getCourseList()
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 24px 0 0 24px;
  // min-height: 800px;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.course-item {
  margin: 0 24px 24px 0;
  width: 208px;
  // height: 226px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(17, 44, 69, 0.02),
    0px 8px 28px 0px rgba(37, 73, 114, 0.05);
  .img-box {
    position: relative;
    width: 100%;
    height: 156px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .pitch-box {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #0089ff;
      background-color: #ebf6ff;
      border-radius: 4px;
    }
    .state-box {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #4d4d4d;
      background-color: #d9d9d9;
      border-radius: 4px;
    }
  }
  .bottom-box {
    padding: 8px 12px 10px;
    .name-box {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img{
        width: 24px;
        height: 25px;
        margin-right: 4px;
      }
      div {
        &:first-child {
          margin-right: 16px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }
      }
    }
    .progress-box {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      /deep/.el-progress {
        width: 118px;
      }
    }
  }
}
.set-box {
  height: calc(100vh - 260px);
  overflow: hidden;
  padding-bottom: 10px;

}
.empty-box{
  height: calc(100vh - 260px);
  overflow: hidden;
}
.pagination {
  padding: 20px;
  text-align: right;
}
</style>
