<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-01-24 17:36:13
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-15 11:09:05
-->
<template>
  <!-- 资源分组 -->
  <div class="group-card">
    <el-card class="lf-true">
      <div slot="header" class="fx-sw-cen">
        <div class="tit-mid">{{title}}</div>
        <div
        class="text-btn-sm cu-p"
        @click.stop="addGroupHandle"
        v-if="pageType !== 'student'"
        >添加</div>
      </div>
      <div>
        <el-tree
        :data="treeData"
        :props="defaultProps"
        :expand-on-click-node="false"
        :highlight-current="true"
        @node-click="handleNodeClick"
        >
          <div
            style="
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;"
              slot-scope="{ node, data }">
              <!-- <el-popover trigger="hover" placement="top" v-if="data.sourceGroup && data.sourceGroup.useDeptName||data.useDeptName">
                <span>{{data.sourceGroup && data.sourceGroup.useDeptName||data.useDeptName}}</span>
                <div slot="reference" class="name-wrapper">
                  {{ node.label }}
                </div>
              </el-popover> -->
              <span >{{ node.label }}</span>
            <el-dropdown v-if="pageType !== 'student'">
              <span>•••</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="editGroupHandle(node)"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item @click.native="deleteGrouphandle(node)"
                  >删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-tree>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '资源分组'
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // defaultProps: {
      //   children: 'childList',
      //   label: 'groupingName'
      // }
    }
  },
  created () {
  },
  methods: {
    editGroupHandle (node) {
      // 编辑分组
      this.$emit('editGroupHandle', node)
    },
    deleteGrouphandle (node) {
      // 删除分组
      this.$emit('deleteGrouphandle', node)
    },
    addGroupHandle () {
      // 添加分组
      this.$emit('addGroupHandle')
    },
    handleNodeClick (data) {
      this.$emit('clickGroupNode', data)
    }
  }
}
</script>

<style lang="scss" scoped>

.lf-true {
  width: 260px;
  margin-right: 8px;

}
/deep/.el-card__body{
  padding: 8px;
}
/deep/.el-tree-node__label {
  font-weight: 500;
  color: #303133;
}
/deep/.is-current{
  background: #EBF6FF;
  color: #0089FF;
}
/deep/.el-tree-node__content{
  line-height: 26px;
}
/deep/.el-tree-node{
  margin-bottom: 4px;
}
// /deep/.el-icon-caret-right {
//   color: #303133;
// }
/deep/.el-card{
  height: 100%;
}

</style>
