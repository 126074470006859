
/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-22 18:55:06
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-27 16:14:26
 */
import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  getCourseGroup: path + '/courseGrouping/employeeCourseGroupTree', // 获取在线课程分组
  getCourseList: path + '/course/employeeCourse', // 课程列表查询
  getCourseDetail: path + '/course/detail'// 课程详情
}
export const getCourseGroupApi = (data) => get(API.getCourseGroup, data)
export const getCourseListApi = (data) => post(API.getCourseList, data)
export const getCourseDetailApi = (data) => get(API.getCourseDetail, data)
